.package-types {
    .input-group > .input-group-prepend > .input-group-text {
        min-width: inherit;
        text-align: right;
    }

    .input-group-text,
    .form-control {
        padding: 0.46875rem 0.625rem;
    }
}