.preloader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 9999;
    background-color: rgba($color: #ffffff, $alpha: 0.98);

    .preloader {
        margin: 35% auto 0;
        position: relative;
        text-align: center;
    
        div {
            color: #000;
            margin: 5px 0;
            text-transform: uppercase;
            text-align: center;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 2px;
        }
    
        .line {
            width: 1px;
            height: 12px;
            background: #000;
            margin: 0 2px;
            display: inline-block;
            animation: preloader-opacity 1000ms infinite ease-in-out;
        }
    
        .line-1 { animation-delay: 800ms; }
        .line-2 { animation-delay: 600ms; }
        .line-3 { animation-delay: 400ms; }
        .line-4 { animation-delay: 200ms; }
        .line-6 { animation-delay: 200ms; }
        .line-7 { animation-delay: 400ms; }
        .line-8 { animation-delay: 600ms; }
        .line-9 { animation-delay: 800ms; }
    }
}

@keyframes preloader-opacity { 
    0% { 
        opacity: 1;
        height: 15px;
    }
    50% { 
        opacity: 0;
        height: 12px;
    }
    100% { 
        opacity: 1;
        height: 15px;
    }  
}