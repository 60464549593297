.device-features-media {
    .nav {
        &.nav-tabs {
            .nav-item {
                .nav-link {
                    text-transform: capitalize;
                }
            }
        }
    }

    .media-body {
        h4 {
            text-transform: capitalize;
        }
        .input-group > .input-group-prepend > .input-group-text {
            text-transform: capitalize;
        }
    }
}

.feature-information {
    .list-group-item {
        > div {
            &:first-of-type {
                min-width: 255px;
            }
            &:last-of-type {
                width: calc(100% - 260px);
            }
        }
    }
}

.device-attributes {
    .legend {
        font-size: 0.875rem;
        letter-spacing: normal;
        padding: 10px;
        background-color: #fff;
        border: 1px solid rgba(72, 94, 144, 0.16);
        border-color: currentColor;
        position: relative;
    
        &::before {
            content: attr(data-label);
            display: block;
            position: absolute;
            top: -6px;
            left: 5px;
            font-size: 8px;
            font-weight: 600;
            font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
            letter-spacing: 1px;
            text-transform: uppercase;
            background-color: inherit;
            color: #8392a5;
            padding: 0 5px;
    
            // updates
            border: 1px solid rgba(72, 94, 144, 0.16);
            top: -12px;
            z-index: 0;
            padding: 5px 10px;
            min-width: 210px;
            background-color: white;
            color: currentColor;
            padding-left: 25px;
            border-color: currentColor;
        }
    
        &::after {
            content: ' ';
            top: -9px;
            background-color: currentColor;
            width: 18px;
            height: 18px;
            left: 8px;
            display: inline-block;
            position: absolute;
            border: 1px solid rgba(72, 94, 144, 0.16);
        }
    
        > div {
            color: #fff;
        }
    
        .rti--tag {
            background-color: #64738f;
            color: #fff;
            padding: 2px 7px;
            border-radius: 4px;
    
            button {
                color: #fff;
                font-size: 10px;
                font-weight: 500;
                padding-right: 1px;
                opacity: .8;
    
                &:hover {
                    color: #000;
                }
            }
        }
    }
}