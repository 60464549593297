.filter-wrapper {
    .btn-filter-heading {
        text-align: left;
        background-color: $gray-200;
        border-color: $gray-200;
        color: $gray-800;
        font-size: $font-size-md;
        font-weight: $font-weight-medium;
        &:hover,
        &:active,
        &:focus {
            background-color: $gray-200 !important;
            border-color: $gray-200 !important;
            color: $gray-800;
        }
        &.collapsed {
            background-color: $gray-400;
            border-color: $gray-400;
            &:hover,
            &:active,
            &:focus {
                background-color: $gray-400 !important;
                border-color: $gray-400 !important;
            }
        }
    }
    .card {
        background-color: $gray-200;
        border-radius: 0;
        border: 0;
    }

    label {
        font-weight: $font-weight-medium;
    }
}