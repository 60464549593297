.dealsheet-upload-details {
    font-size: .8rem;

    .dealsheet-upload-details__title {
        h1, h2, h3, h4, h5, h6 {
            color: $navy;
            font-weight: 700;
        }

        h3 {
            margin-top: -4px;
            text-transform: uppercase;
        }

        p {
            margin: 0;
            padding: 0;
            margin-bottom: 7px;
        }

        .card {
            min-height: 156px;
        }
    }

    .dealsheet-upload-details__content {
        .nav-tabs {
            .nav-link {
                display: inline-block;
                min-width: 150px;
                color: white;
                background-color: $navy;

                small,
                span {
                    display: block;
                    color: white;
                }
                small {
                    margin-top: 4px;
                    margin-bottom: -4px;
                }
                span {
                    text-transform: uppercase;
                    font-size: .85rem;
                    font-weight: 700;
                }

                &.active {
                    background-color: white;
                    small { color: #707070; }
                    span { color: $navy; }

                    span {
                        font-size: .88rem;
                    }
                }
            }
        }
        .tab-content {
            .tab-pane {
                .custom-checkbox {
                    cursor: pointer;
                    .custom-control-input:checked ~ .custom-control-label::after {
                        top: .48rem;
                    }
                    .custom-control-input:checked ~ .custom-control-label::before {
                        background-color: #707070;
                        border-color: #707070;
                    }
                    .custom-control-label {
                        cursor: pointer;
                        &::before {
                            top: .48rem;
                        }
                    }
                }
                .rdt_Table {
                    color: #707070;

                    .rdt_TableHeadRow {
                        margin-top: 20px;
                        min-height: 30px;
                    }
                    .rdt_TableCol,
                    .rdt_TableCell {
                        color: #707070;
                        padding-left: 10px !important;
                    }
    
                    .rdt_TableRow {
                        border: none;
                        min-height: 60px;
                    }
                }
            }
        }
    }

    .matching-info-wrapper {
        margin-top: 20px;

        .heading {
            font-weight: 500;
        }

        .row {
            margin-right: -5px;
            margin-left: -5px;
        }

        .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
            padding-right: 5px;
            padding-left: 5px;
        }

        .table-row {
            padding: 10px 0;
            &:hover {
                background-color: hsl(0,0%,95%);
            }
        }
    }
}

.css-1g9elxf-menu {
    z-index: 999 !important;
}