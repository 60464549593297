@media (max-width: 991.98px) {
    .app-filemgr.filemgr-sidebar-show .filemgr-sidebar {
      left: 0;
      opacity: 1;
      visibility: visible; }
    .app-filemgr.filemgr-sidebar-show .filemgr-content {
      transform: translateX(240px); } }
  
  .filemgr-wrapper {
    position: fixed;
    top: 55px;
    bottom: 0;
    left: 0;
    right: 0; }
    @media (min-width: 992px) {
      .filemgr-wrapper {
        top: 60px; } }
  
  @media (min-width: 992px) {
    .filemgr-wrapper-two {
      position: relative;
      height: 100%;
      top: auto; } }
  
  .filemgr-sidebar {
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -240px;
    width: 240px;
    border-right: 1px solid rgba(72, 94, 144, 0.16);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s; }
    @media (prefers-reduced-motion: reduce) {
      .filemgr-sidebar {
        transition: none; } }
    @media (min-width: 992px) {
      .filemgr-sidebar {
        left: 0;
        opacity: 1;
        visibility: visible; } }
  
  .filemgr-sidebar-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 55px;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    display: flex;
    align-items: center;
    padding: 0 20px; }
    .filemgr-sidebar-header button {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 10px;
      font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
      letter-spacing: .5px; }
      .filemgr-sidebar-header button.btn-white {
        font-weight: 700; }
      .filemgr-sidebar-header button + button {
        margin-left: 10px; }
      .filemgr-sidebar-header button svg {
        width: 12px;
        height: 12px;
        stroke-width: 2.5px;
        margin-top: -1px; }
  
  .filemgr-sidebar-body {
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    bottom: 0; }
  
  .filemgr-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s; }
    @media (prefers-reduced-motion: reduce) {
      .filemgr-content {
        transition: none; } }
    @media (min-width: 992px) {
      .filemgr-content {
        left: 240px; } }
  
  .filemgr-content-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 55px;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    display: flex;
    align-items: center;
    padding: 0 20px; }
    .filemgr-content-header .feather-search {
      color: #8392a5;
      align-self: center;
      width: 18px;
      stroke-width: 2.8px;
      margin-right: 10px;
      margin-top: -2px; }
    .filemgr-content-header .search-form {
      display: flex;
      align-items: center; }
      .filemgr-content-header .search-form .form-control {
        border-width: 0;
        background-color: transparent;
        font-size: inherit;
        padding: 0;
        color: #1b2e4b; }
    .filemgr-content-header .nav-link {
      padding: 0; }
      .filemgr-content-header .nav-link + .nav-link {
        margin-left: 10px; }
      .filemgr-content-header .nav-link svg {
        width: 20px;
        height: 20px;
        stroke-width: 2.2px; }
        .filemgr-content-header .nav-link svg.feather-settings {
          width: 18px;
          height: 18px; }
  
  .filemgr-content-body {
    background-color: #f8f9fc;
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    bottom: 0; }
    @media (min-width: 1200px) {
      .filemgr-content-body > div:first-child {
        max-width: 1140px; } }
  
  .dropdown-file {
    position: absolute;
    top: 8px;
    right: 4px; }
    .dropdown-file .dropdown-link {
      color: rgba(131, 146, 165, 0.5); }
      .dropdown-file .dropdown-link:hover, .dropdown-file .dropdown-link:focus {
        color: #1b2e4b; }
      .dropdown-file .dropdown-link svg {
        width: 18px;
        height: 18px; }
    .dropdown-file .dropdown-item {
      font-size: 12px;
      display: flex;
      align-items: center;
      padding: 5px 10px; }
      .dropdown-file .dropdown-item svg {
        width: 14px;
        height: 14px;
        stroke-width: 2.5px;
        margin-right: 10px;
        color: #8392a5; }
  
  .card-file {
    padding: 10px;
    position: relative;
    border-color: rgba(72, 94, 144, 0.16);
    transition: all 0.2s ease-in-out; }
    .selector {
      position: absolute;
      bottom: 5px;
      right: 10px;
      z-index: 9;
      input {
        width: 17px;
        height: 17px; } }
    .tag-label {
      position: absolute;
      top: 12px;
      left: -7px;
      z-index: 9;
      background: #3EA42D;
      display: inline-block;
      padding: 4px 15px;
      span { color: white !important; } }
    @media (prefers-reduced-motion: reduce) {
      .card-file {
        transition: none; } }
    .card-file:hover, .card-file:focus {
      border-color: #c0ccda; }
    .card-file .card-body {
      padding: 15px 0 0; }
    .card-file .card-footer {
      font-size: 11px;
      color: #c0ccda;
      padding: 10px 0 0;
      border-top-width: 0;
      background-color: transparent; }
    .card-file h6 {
      margin-bottom: 0; }
      .card-file h6 a {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        width: 100%; }
    .card-file p {
      margin-bottom: 0;
      color: #475362; }
    .card-file span {
      font-size: 12px;
      color: #8392a5; }
  
  .card-file-thumb {
    background-color: #f5f6fa;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px; }
    @media (min-width: 576px) {
      .card-file-thumb {
        height: 100px;
        font-size: 40px; } }
    @media (min-width: 992px) {
      .card-file-thumb {
        height: 120px;
        font-size: 48px; } }
  
  .media-folder {
    position: relative;
    border: 1px solid rgba(72, 94, 144, 0.16);
    padding: 10px 12px;
    background-color: #fff;
    border-radius: 0.25rem;
    transition: all 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .media-folder {
        transition: none; } }
    .media-folder:hover, .media-folder:focus {
      border-color: #c0ccda; }
    .media-folder > svg {
      width: 42px;
      height: 42px;
      stroke-width: 1.5px;
      color: #0168fa;
      opacity: .7; }
    .media-folder .media-body {
      margin-top: 5px;
      margin-left: 10px; }
      .media-folder .media-body h6 {
        margin-bottom: 1px; }
      .media-folder .media-body span {
        font-size: 12px;
        color: #8392a5;
        display: block; }
  
  @media (min-width: 992px) {
    .aside-filemgr + .content {
      margin-left: 60px; } }