.steps-wrapper {
    .steps {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: flex-start;

        .step-item {
            display: inline-block;
            position: relative;

            .step-link {
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 12px;
                color: #1b2e4b;
                background-color: #fff;
                padding: 5px 7px;
            }

            &.active {
                .step-link {
                    background-color: transparent;
                    color: #0168fa;
                }
                .step-number {
                    border-color: #fff;
                }
            }

            &.complete {
                .step-link {
                    background-color: transparent;
                    color: #10b759;
                }
                .step-number {
                    border-color: #fff;
                }
            }

            &.disabled {
                .step-link {
                    color: #8392a5;
                    cursor: default;
                }
            }
        }
        
        .steps-tab .step-item.complete .step-number {
            border-color: #fff;
        }

        &.steps-justified {
            display: flex;
            align-items: center;
    
            .step-item {
                flex: 1;

                .step-link {
                    width: 100%;
                }
            }
        }
    }
}

.wizard {
    .content {
        .table {
            td {
                &:first-child {
                    padding-top: 15px;
                }
                &:nth-child(2) {
                    padding-top: 12px;
                }
            }
        }
        select {
            border-color: #ccc;
            border-radius: 5px;
            padding: 3px 10px;
        }

        .custom-control {
            &.custom-switch {
                input + label {
                    cursor: pointer;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .steps-wrapper {
        .steps {
            .step-item {
                .step-link {
                    font-size: 14px;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .steps-wrapper {
        .steps {
            .step-item {
                .step-link {
                    font-size: 14px;
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .steps-wrapper {
        .steps {
            .step-link {
                font-size: 13px;
            }
            &.steps-tab {
                .step-link {
                    padding: 8px 15px;
                }
            }
        }
    }
}