.bulk-action-wrapper {
    .card {
        border: 0;
        border-radius: 0;

        .card-body {
            padding-bottom: 0;
        }
    }
    .form-group {
        background-color: $gray-200;
        min-height: 120px;
        .card {
            background-color: transparent;
        }
        label {
            font-weight: $font-weight-medium;
        }
    }
}