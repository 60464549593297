.page__container {
    margin-top: 60px;
    &.invert {
        background-image: linear-gradient(90deg, #122272 0%, #1940b0 100%);
        color: #dfdfe9;
        border-bottom-color: rgba(0,0,0,.2);
    }

    .navbar-light {
        .navbar-toggler {
            border: none;
        }
        .navbar-nav {
            .nav-link {
                color: #ebebf5;
                background: 0 0;
                &.active {
                    color: #16a3fe;
                    .icon {
                        color: #16a3fe;
                    }
                }
                .icon {
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    margin-right: 10px;
                    float: left;
                    color: #FFF;
                    font-size: 20px;
                }
                .text {
                    float: left;
                    line-height: 18px;
                    margin-top: 2px;
                }
            }
        }
    }
}

.page__header {
    .box {
        .user.user--lg {
            img + .user__name {
                padding-left: 60px;
            }
        }
    }
}

.dropdown-profile {
    .dropdown-menu {
        margin-top: 20px;
    }
    .dropdown-link {
        color: inherit;
        cursor: default;
        span { color: $primary; cursor: pointer; }
    }
}

// navbar header
.navbar-header .navbar-right {
    .btn-social {
        font-size: 14px;
        span { font-weight: bold }
        &:hover,
        &:active,
        &:focus {
            color: inherit;
            cursor: default;
            span { color: $primary; cursor: pointer; }
        }
    }
}

@media (min-width: 1200px) {
    .navbar-header .navbar-right {
        width: 400px;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        .navbar-nav {
            .nav-link {
                padding-right: 1.2rem;
                padding-left: .5rem;
            }
        }
    }
}