// general

.top-0 { top: 0 !important }

.Select-menu-outer {
    z-index: 999 !important;
}

.text-navy {
    color: $navy
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

.font-size-10 {
    font-size: 10px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-19 {
    font-size: 19px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-21 {
    font-size: 21px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.font-size-23 {
    font-size: 23px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-25 {
    font-size: 25px !important;
}

.font-size-26 {
    font-size: 26px !important;
}

.font-size-27 {
    font-size: 27px !important;
}

.font-size-28 {
    font-size: 28px !important;
}

.font-size-29 {
    font-size: 29px !important;
}

.font-size-30 {
    font-size: 30px !important;
}

.btn {
    &.btn-disabled {
        background-color: #919191;
        border-color: #919191;
        color: white;
        opacity: .4;
    }
}

// custom input with dropdown
.input-with-dropdown-options {
    position: relative;

    .dropdown-options {
        position: relative;
        width: 100%;
        max-height: 250px;
        overflow-y: auto;
        list-style: none;
        padding: 0 15px 3px 24px;
        border-left: 1px solid rgba(72, 94, 144, 0.16);
        margin-top: 10px;
        margin-left: 28px;
        z-index: 1000;
        display: none;

        &::before {
            display: none;
            content: '';
            position: absolute;
            top: -11px;
            right: 25px;
            border-bottom: 10px solid rgba(192, 204, 218, 0.53);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }

        &::after {
            display: none;
            content: '';
            position: absolute;
            top: -8.5px;
            right: 26px;
            border-bottom: 9px solid #fff;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
        }

        .option-item {
            .option-link {
                color: #1b2e4b;
                padding: 3px 0;
                white-space: nowrap;
                font-size: 13px;
                display: flex;
                align-items: center;
                transition: all 0.2s ease-in-out;

                &:hover,
                &:focus,
                &:active {
                    color: $primary;
                }
            }
        }
    }

    &.show {
        .dropdown-options {
            display: block;

            &::before,
            &::after {
                display: block;
            }
        }
    }
}

.custom-control {
    &.custom-switch {
        .custom-control-input {
            &:hover {
                cursor: pointer;
            }
        }

        .custom-control-label {
            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: 1200px) {
    .input-with-dropdown-options {
        .dropdown-options {
            margin-left: 30px;
        }
    }
}

@media (min-width: 992px) {
    .input-with-dropdown-options {
        .dropdown-options {
            position: absolute;
            top: 37.5px;
            left: -25px;
            background-color: #fff;
            padding: 10px;
            border: 1px solid rgba(192, 204, 218, 0.53);
            margin-top: 0;
            margin-left: 25px;
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
        }
    }
}

// loading
.loading {
    position: absolute;
    background: rgba(255, 255, 255, .9);
    left: 50%;
    top: 50%;
    margin-top: -8px;
    margin-left: -20px;
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center
}

.divider--dashed:after,
.divider--dashed:before,
.page-aside--hidden>.loading,
.page-sidepanel--hidden>.loading {
    display: none
}

.loading--solid {
    background: #fff
}

.loading>div {
    position: relative;
    width: 20px;
    height: 20px
}

.loading--w-spinner>div .loading-spinner {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    animation: rotate .5s infinite linear
}

.loading--w-spinner>div .loading-spinner:before {
    position: absolute;
    left: 3px;
    top: 3px;
    content: " ";
    width: 14px;
    height: 14px;
    border: 2px solid #323c47;
    border-right-color: transparent;
    border-radius: 7px
}

.loading--text.loading--w-spinner>div {
    padding-left: 25px;
    width: auto
}

.loading--text>div {
    font-size: 11px;
    font-weight: 600;
    line-height: 22px
}

.loading--dark {
    color: #FFF;
    background: rgba(0, 0, 0, .8)
}

.loading--dark.loading--w-spinner>div .loading-spinner:before {
    border-color: #FFF transparent #FFF #FFF
}

.loading--dark.loading--solid {
    background: #000
}

.loading.fadeOut {
    animation: fadeOut .2s linear
}

// overwrite (the above) loading component
.page-body-container {
    .loading {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

.loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background-color: rgba($color: white, $alpha: .95);
}

.react-datepicker-wrapper {
    display: block !important;
}

@keyframes rotate {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(359deg)
    }
}

// page loader
.page-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0px;
    margin: auto;
    text-align: center;
    width: 100%;
    min-height: 400px;
    height: 100%;
    background: #F6F6F6;
    z-index: 999;
}

.spinner {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 70px;
    height: 70px;
    margin: -80px 0 0 -35px;
    z-index: 1001
}

.spinner-round {
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #16a3fe;
    animation: spin 2s linear infinite
}

.spinner-round:before,
.spinner-round:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 3px solid transparent
}

.spinner-round:before {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-top-color: #23c99d;
    animation: spin 3s linear infinite
}

.spinner-round:after {
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-top-color: #122272;
    animation: spin 1.5s linear infinite
}

.step-item {
    &.error {
        .step-link {
            color: #dc3545;
        }

        .step-number,
        .step-icon {
            background-color: #f5c6cb;
            border-color: #dc3545;
            color: #dc3545;
        }
    }

    &.warning {
        .step-link {
            color: #edb100;
        }

        .step-number,
        .step-icon {
            background-color: #ffeeba;
            border-color: #edb100;
            color: #edb100;
        }
    }
}

// datatable
.rdt_Table {
    .rdt_TableCell,
    .rdt_TableCol {
        &:first-of-type {
            padding-left: 15px;
        }

        &:last-of-type {
            padding-right: 15px;
        }
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@media print {
    .hide-on-print {
        display: none;
    }
}

// fieldset
.dams-fieldset {
    font-size: 0.875rem;
    letter-spacing: normal;
    padding: 10px;
    background-color: #fff;
    border: 1px solid rgba(72, 94, 144, 0.16);
    position: relative;
}

@media (min-width: 992px) {
    .dams-fieldset {
        padding: 20px;
    }
}

@media (min-width: 1200px) {
    .dams-fieldset {
        padding: 25px;
    }
}

.dams-fieldset::before {
    content: attr(data-label);
    display: block;
    position: absolute;
    top: -6px;
    left: 5px;
    font-size: 8px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: inherit;
    color: #8392a5;
    padding: 0 5px;
}

.dams-fieldset.df-inverse {
    background-color: #1c273c;
    color: rgba(255, 255, 255, 0.7);
    border-width: 0;
}

.dams-fieldset.df-inverse::before {
    background-color: #3b4863;
    top: -16px;
    left: 0;
}

// legend
.legend {
    font-size: 0.875rem;
    letter-spacing: normal;
    padding: 10px;
    background-color: #fff;
    border: 1px solid rgba(131, 146, 165, 0.16);
    border-color: currentColor;
    position: relative;

    &::before {
        content: attr(data-label);
        display: block;
        position: absolute;
        top: -6px;
        left: 5px;
        font-size: 8px;
        font-weight: 600;
        font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
        letter-spacing: 1px;
        text-transform: uppercase;
        background-color: inherit;
        color: #8392a5;
        padding: 0 5px;
    }
}

// file manager
.file-manager-wrapper {
    .media {
        display: flex;
        align-items: flex-start;
    }

    .media-folder {
        position: relative;
        border: 1px solid rgba(72, 94, 144, 0.16);
        padding: 10px 12px;
        background-color: #fff;
        border-radius: 0.25rem;
        transition: all 0.2s ease-in-out;
    }
}

.fileContainer {
    .uploadPictureContainer {
        .deleteImage {
            background-color: #0168fa;
            top: -12px;
            right: -12px;
            width: 25px;
            height: 25px;
            font-size: 15px;
            line-height: 25px;
        }
    }
}

.input-group>.input-group-prepend>.input-group-text {
    min-width: 110px;
    display: inline-block;
    text-align: left;
    font-weight: 500;
    background-color: transparent;
}

.card-file {
    .img-selected-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, .05);
        border: 1px solid $tertiary;
        border-radius: 3px;
        display: none;
    }

    &.selected {
        .img-selected-overlay {
            display: block;
        }
    }
}

.wizard>.actions .disabled a,
.wizard>.actions .disabled a:hover,
.wizard>.actions .disabled a:active {
    background-color: #97a3b9 !important;
    cursor: not-allowed;
}

.wizard>.actions .is-invalid a:not(.btn-white) {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.wizard>.actions a.btn-white,
.wizard>.actions a.btn-white:hover,
.wizard>.actions a.btn-white:active {
    background-color: inherit;
    color: inherit;
    border-color: inherit;
}

.ui-accordion-content {
    display: none;

    &.ui-accordion-content-active {
        display: block;
    }
}

.page-body-container {
    .filter-wrapper {
        background-color: white;

        .btn-filter-heading {
            background-color: #FCFCFC;
            border-bottom: 1px solid rgba(72, 94, 144, 0.16);

            &:hover,
            &:active,
            &:focus {
                background-color: #FCFCFC !important;
            }

            &::before {
                content: "\f368";
                display: inline-block;
                font-family: "Ionicons";
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                text-rendering: auto;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                margin-right: 10px;
                transition: all ease 300ms;
            }

            &.collapsed {
                &::before {
                    content: "\f273";
                    transition: all ease 300ms;
                }
            }
        }

        .card {
            background-color: #FCFCFC;
            border-bottom: 1px solid rgba(72, 94, 144, 0.16);
        }
    }
}

@media (min-width: 992px) {
    .legend {
        padding: 20px;
    }
}