.modal-title {
    span {
        font-size: $font-size-sm;
        font-weight: $font-weight-light;
    }
}

.draft-wysiwyg-wrapper {
    border: 1px solid $gray-200;
    padding: 5px;
    .dw-editor {
        border: 1px solid $gray-200;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.special-text-modal {
    .modal-header { border: 0 }
    .modal-footer { border: 0 }
    .modal-body { padding-top: 0; padding-bottom: 0; }
}