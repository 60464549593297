@media (max-width: 991.98px) {
    .app-contact.contact-content-show .contact-navleft,
    .app-contact.contact-content-show .contact-sidebar {
      visibility: hidden;
      transform: translateX(-350px); }
    .app-contact.contact-content-show .contact-content {
      visibility: visible;
      transform: translateX(0);
      opacity: 1; } }
  
  @media (max-width: 991.98px) {
    .app-contact.contact-content-visible .contact-content {
      visibility: visible;
      transform: translateX(305px);
      opacity: 1; } }
  
  @media (max-width: 1199.98px) {
    .app-contact.contact-options-show .contact-content {
      transform: translateX(-260px); } }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .app-contact.contact-options-show .contact-navleft,
    .app-contact.contact-options-show .contact-sidebar {
      transform: translateX(-260px); } }
  
  @media (min-width: 1200px) {
    .app-contact.contact-options-show .contact-content-sidebar {
      opacity: 1;
      visibility: visible; } }
  
  .contact-wrapper {
    background-color: #f8f9fc;
    position: fixed;
    top: 55px;
    bottom: 0;
    left: 0;
    right: 0; }
    @media (min-width: 992px) {
      .contact-wrapper {
        top: 60px; } }
  
  .contact-wrapper-two {
    position: relative;
    height: 100%;
    top: 0; }
    .contact-wrapper-two .contact-content-header {
      right: 0; }
    .contact-wrapper-two .contact-content-body {
      right: 0; }
    @media (min-width: 1200px) {
      .contact-wrapper-two .contact-content-sidebar {
        opacity: 0;
        visibility: hidden; } }
  
  .contact-navleft,
  .contact-sidebar,
  .contact-content {
    transition: all 0.3s; }
    @media (prefers-reduced-motion: reduce) {
      .contact-navleft,
      .contact-sidebar,
      .contact-content {
        transition: none; } }
  
  .contact-navleft {
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    border-right: 1px solid rgba(72, 94, 144, 0.16);
    width: 54px;
    padding: 15px 10px; }
    @media (min-width: 992px) {
      .contact-navleft {
        width: 60px; } }
    .contact-navleft .nav-link {
      padding: 0;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1b2e4b;
      border-radius: 0.25rem;
      transition: all 0.2s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .contact-navleft .nav-link {
          transition: none; } }
      .contact-navleft .nav-link:hover, .contact-navleft .nav-link:focus {
        color: #1b2e4b; }
      .contact-navleft .nav-link.active {
        color: #0168fa; }
        .contact-navleft .nav-link.active svg {
          fill: rgba(1, 104, 250, 0.2); }
      .contact-navleft .nav-link + .nav-link {
        margin-top: 10px; }
      .contact-navleft .nav-link svg {
        width: 20px;
        stroke-width: 2.2px;
        fill: rgba(27, 46, 75, 0.1); }
  
  .contact-sidebar {
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 55px;
    right: 0; }
    @media (min-width: 480px) {
      .contact-sidebar {
        right: auto;
        width: 250px;
        border-right: 1px solid rgba(72, 94, 144, 0.16); } }
    @media (min-width: 992px) {
      .contact-sidebar {
        width: 265px;
        left: 60px; } }
    @media (min-width: 1200px) {
      .contact-sidebar {
        width: 280px; } }
  
  .contact-sidebar-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 55px;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    display: flex;
    align-items: center;
    padding: 0 15px; }
    .contact-sidebar-header .feather-search {
      color: #8392a5;
      align-self: center;
      width: 18px;
      stroke-width: 2.8px;
      margin-right: 10px;
      margin-top: -2px; }
    .contact-sidebar-header .search-form {
      flex: 1;
      display: flex;
      align-items: center; }
      .contact-sidebar-header .search-form .form-control {
        border-width: 0;
        background-color: transparent;
        font-size: inherit;
        padding: 0;
        color: #1b2e4b; }
  
  .contact-sidebar-body {
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    bottom: 0; }
    .contact-sidebar-body .tab-pane:first-child .contact-list-divider::before {
      width: 27px; }
  
  .contact-list .media {
    color: #1b2e4b;
    padding: 10px;
    align-items: flex-end;
    border-radius: 0.25rem; }
    .contact-list .media:hover, .contact-list .media:focus {
      background-color: #f5f6fa;
      cursor: default; }
      .contact-list .media:hover .avatar-online::after,
      .contact-list .media:hover .avatar-offline::after, .contact-list .media:focus .avatar-online::after,
      .contact-list .media:focus .avatar-offline::after {
        box-shadow: 0 0 0 2px #eff2f7; }
      @media (min-width: 992px) {
        .contact-list .media:hover nav, .contact-list .media:focus nav {
          display: flex; } }
    .contact-list .media + .media {
      margin-top: 2px; }
    @media (min-width: 480px) {
      .contact-list .media.active {
        background-color: #eef0f7; }
        .contact-list .media.active .avatar-online::after,
        .contact-list .media.active .avatar-offline::after {
          box-shadow: 0 0 0 2px #e5e9f2; } }
  
  .contact-list .media-body span {
    color: #8392a5;
    display: block;
    line-height: 1.02; }
  
  .contact-list nav {
    display: none;
    align-items: center;
    align-self: center; }
    .contact-list nav a {
      display: block;
      color: #8392a5; }
      .contact-list nav a:hover, .contact-list nav a:focus {
        color: #1b2e4b; }
      .contact-list nav a + a {
        margin-left: 5px; }
    .contact-list nav svg {
      width: 16px; }
  
  .contact-list-divider {
    display: flex;
    align-items: center;
    color: #8392a5;
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    text-transform: uppercase;
    letter-spacing: .5px;
    margin: 15px -10px 10px; }
    .contact-list-divider:first-child {
      margin-top: 0; }
    .contact-list-divider::before, .contact-list-divider::after {
      content: '';
      display: block;
      height: 1px;
      background-color: rgba(229, 233, 242, 0.7); }
    .contact-list-divider::before {
      margin-right: 5px;
      width: 15px; }
    .contact-list-divider::after {
      margin-left: 5px;
      flex: 1; }
  
  .contact-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    transform: translateX(100vw);
    opacity: 0;
    transition: all 0.3s; }
    @media (prefers-reduced-motion: reduce) {
      .contact-content {
        transition: none; } }
    @media (min-width: 480px) {
      .contact-content {
        left: 0;
        transform: translateX(320px); } }
    @media (min-width: 992px) {
      .contact-content {
        left: 325px;
        transform: none;
        visibility: visible;
        opacity: 1;
        &.fluid {
          left: 0;
        }
      }
    }
    @media (min-width: 1200px) {
      .contact-content {
        left: 340px;
        &.fluid {
          left: 0;
        }
      } 
    }
  
  .contact-content-header {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 55px;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    display: flex;
    align-items: center;
    padding: 0 20px; }
    @media (min-width: 1200px) {
      .contact-content-header {
        right: 290px;
        padding: 0 25px;
      }
    }
    .contact-content-header .nav-link {
      color: #8392a5;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: .5px;
      padding: 0; }
      .contact-content-header .nav-link:hover, .contact-content-header .nav-link:focus {
        color: #525f70; }
      .contact-content-header .nav-link.active {
        color: #0168fa;
        font-weight: 600;
        position: relative; }
        .contact-content-header .nav-link.active::before {
          content: '';
          position: absolute;
          bottom: -19px;
          left: 0;
          width: 100%;
          border-bottom: 1px solid #0168fa; }
      .contact-content-header .nav-link + .nav-link {
        margin-left: 30px; }
      .contact-content-header .nav-link span {
        display: none; }
        @media (min-width: 576px) {
          .contact-content-header .nav-link span {
            display: inline; } }
  
  .contact-content-body {
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    bottom: 0; }
    @media (min-width: 1200px) {
      .contact-content-body {
        right: 290px; } }
    @media (min-width: 1200px) {
      .contact-content-body .tab-pane > .row {
        max-width: 800px; } }
  
  .contact-content-sidebar {
    background-color: #fff;
    position: absolute;
    top: 0;
    right: -260px;
    bottom: 0;
    border-left: 1px solid rgba(72, 94, 144, 0.16);
    width: 260px;
    padding: 20px; }
    @media (min-width: 1200px) {
      .contact-content-sidebar {
        width: 290px;
        padding: 25px;
        right: 0; } }
  
  .contact-edit-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background-color: #e5e9f2;
    color: #8392a5;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 0 3px #fff; }
    .contact-edit-photo:hover, .contact-edit-photo:focus {
      color: #1b2e4b;
      background-color: #c0ccda; }
    .contact-edit-photo svg {
      width: 12px;
      stroke-width: 3px; }
  
  .contact-call-nav {
    display: flex; }
    .contact-call-nav a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 34px;
      height: 34px;
      border-radius: 100%;
      color: #fff; }
      .contact-call-nav a svg {
        width: 16px;
        stroke-width: 2.6px; }
      .contact-call-nav a + a {
        margin-left: 8px; }
      .contact-call-nav a.nav-call {
        background-color: #00cccc; }
        .contact-call-nav a.nav-call:hover, .contact-call-nav a.nav-call:focus {
          background-color: #009999; }
      .contact-call-nav a.nav-video {
        background-color: #f10075; }
        .contact-call-nav a.nav-video:hover, .contact-call-nav a.nav-video:focus {
          background-color: #be005c; }
      .contact-call-nav a.nav-msg {
        background-color: #0168fa; }
        .contact-call-nav a.nav-msg:hover, .contact-call-nav a.nav-msg:focus {
          background-color: #0153c7; }
  
  .contact-actions {
    display: flex;
    position: relative;
    margin-top: -2px; }
    .contact-actions a {
      display: flex;
      align-items: flex-end;
      color: #1b2e4b; }
      .contact-actions a:hover, .contact-actions a:focus {
        color: #0168fa; }
      .contact-actions a svg {
        width: 16px;
        stroke-width: 2.6px;
        margin-right: 5px; }
      .contact-actions a + a {
        margin-left: 20px; }
  
  .contact-content-nav .nav-link {
    color: #1b2e4b;
    display: flex;
    align-items: center;
    padding: 0; }
    .contact-content-nav .nav-link:hover, .contact-content-nav .nav-link:focus {
      color: #0168fa; }
    .contact-content-nav .nav-link + .nav-link {
      margin-top: 10px; }
  
  .contact-content-nav svg {
    position: relative;
    margin-top: -2px;
    width: 18px;
    margin-right: 12px; }