.wysiwyg-text-editor {
    .fr-box {
        &.fr-basic {
            border-radius: 0;
            -moz-border-radius: 0;
            -webkit-border-radius: 0;

            .fr-toolbar {
                &.fr-top {
                    border-color: #c0ccda;
                    border-radius: 0;
                    -moz-border-radius: 0;
                    -webkit-border-radius: 0;
                }
            }

            .fr-wrapper {
                border-color: #c0ccda;
                border-bottom: none;
            }
            .fr-second-toolbar {
                border-color: #c0ccda;
            }
        }
    }
}