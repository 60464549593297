.dealsheet-result-table-wrapper {
    .card {
        border: 0;

        .table {
            tbody {
                tr {
                    td {
                        span {
                            display: block;
                            color: $gray-600;
                        }
                        .form-control,
                        .custom-select {
                            margin-top: -5px;
                        }
                    }
                    &:first-of-type {
                        td { padding-top: 24px }
                    }
                    &:last-of-type {
                        border-bottom: 1px solid rgba(72, 94, 144, 0.16);
                    }
                }
            }
        }
    }

    .export-csv-wrapper {
        &.form-group {
            background-color: $gray-200;
            .card {
                background-color: transparent;
                p { margin-bottom: 0; font-size: $font-size-xs; }
            }
        }
    }
}